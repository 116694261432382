body {
  margin: 0;
  padding: 0;
  padding-right: 0 !important;
  font-family: sans-serif;
  background-color: #eeeeee !important;
}

.mui-fixed {
  padding-right: 0 !important;
}

.reset-search-bar input {
  width: 100%;
  padding: 0;
  position: relative;
  top: 4px;
}

.grecaptcha-badge {
  display: none !important;
}
