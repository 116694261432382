@font-face {
  font-family: 'TradeGothicLight';
  src: url('../../Fonts/Trade-Gothic-Complete-Family-Pack/Linotype-TradeGothicLTStd-Light.otf')
    format('opentype');
}

.tcDiv p {
  font-family: TradeGothicLight;
  font-weight: 100;
}

.TermsDialog_Buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: stretch;
}

.TermsDiaol_scrollable-content {
  height: 50vh;
  overflow: auto;
  background-color: white;
  padding: 20px;
}

.TermsFullPage_Container {
  max-width: 1000px;
  padding: 100px 0px 50px 0px;
  margin: auto;
}

.tcDiv ol {
  counter-reset: item;
  list-style-position: outside;
}

.tcDiv ol li {
  display: block;
}

.tcDiv ol li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
}

.tcDiv ol > li > ol > li > ol > li {
  display: list-item;
  list-style-type: lower-alpha;
}
.tcDiv ol > li > ol > li > ol > li:before {
  counter-increment: none;
  content: none;
  color: red;
}

.alphabetList > li {
  display: list-item !important;
  list-style-type: lower-alpha !important;
}

.alphabetList > li:before {
  counter-increment: none !important;
  content: none !important;
}

/* h4:first-child {
	color: red;
	font-size: 44px;
	font-weight: normal;
	color: #484848;
	margin: 0;
}

h4:nth-child(2){
	padding: 6px 0;
    font-size: 16px;
    color: #767676;
    text-decoration: none;
}

h4:nth-child(n+3){
	font-size: 14px;
	line-height: 1.43;
	color: #484848;
	
} */

.tcTitle {
  color: #484848;
  font-size: 44px;
  margin: 0;
  text-align: center;
}

.tcLastUpdated {
  padding: 6px 0;
  font-size: 16px;
  color: #767676;
  text-decoration: none;
}

.tcDiv ol li {
  margin-top: 8px;
}
