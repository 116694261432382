/* Forza Font Family: used primarily for basic site copy: paragraphs, buttons, input text, etc...
--------------------------------------------------------------------------------------------*/
@font-face {
  font-family: 'forzaBook';
  src: url('./Fonts/HCo_Forza/OpenType/Forza-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'forzaBookItalic';
  src: url('./Fonts/HCo_Forza/OpenType/Forza-BookItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'forzaMedium';
  src: url('./Fonts/HCo_Forza/OpenType/Forza-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'forzaThin';
  src: url('./Fonts/HCo_Forza/OpenType/Forza-Thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'forzaBlack';
  src: url('./Fonts/HCo_Forza/OpenType/Forza-Black.otf') format('opentype');
}

@font-face {
  font-family: 'forzaBold';
  src: url('./Fonts/HCo_Forza/OpenType/Forza-Bold.otf') format('opentype');
}

/* Button font */

@font-face {
  font-family: 'Saira-Condensed-Thin';
  src: url('./Fonts/Saira_Condensed/SairaCondensed-Thin.ttf') format('trueType');
}

@font-face {
  font-family: 'Saira-Condensed-Extra-Light';
  src: url('./Fonts/Saira_Condensed/SairaCondensed-ExtraLight.ttf') format('trueType');
}

@font-face {
  font-family: 'Saira-Condensed-Light';
  src: url('./Fonts/Saira_Condensed/SairaCondensed-Light.ttf') format('trueType');
}

@font-face {
  font-family: 'Saira-Condensed-Regular';
  src: url('./Fonts/Saira_Condensed/SairaCondensed-Regular.ttf') format('trueType');
}

@font-face {
  font-family: 'Saira-Condensed-Medium';
  src: url('./Fonts/Saira_Condensed/SairaCondensed-Medium.ttf') format('trueType');
}

@font-face {
  font-family: 'Saira-Condensed-Semi-Bold';
  src: url('./Fonts/Saira_Condensed/SairaCondensed-SemiBold.ttf') format('trueType');
}

@font-face {
  font-family: 'Saira-Condensed-Bold';
  src: url('./Fonts/Saira_Condensed/SairaCondensed-Bold.ttf') format('trueType');
}

@font-face {
  font-family: 'Saira-Condensed-Extra-Bold';
  src: url('./Fonts/Saira_Condensed/SairaCondensed-ExtraBold.ttf') format('trueType');
}

@font-face {
  font-family: 'Saira-Condensed-Black';
  src: url('./Fonts/Saira_Condensed/SairaCondensed-Black.ttf') format('trueType');
}

/* Trade Gothic Font Family: used primarily for headers, and labels...
--------------------------------------------------------------------------------*/
@font-face {
  font-family: 'TG-Bold';
  src: url('./Fonts/Trade-Gothic-Complete-Family-Pack/Linotype-TradeGothicLTStd-Bold.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TG-Bold-Condensed-20';
  src: url('./Fonts/Trade-Gothic-Complete-Family-Pack/Linotype-TradeGothicLTStd-BdCn20.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TG-Condensed-18';
  src: url('./Fonts/Trade-Gothic-Complete-Family-Pack/Linotype-TradeGothicLTStd-Cn18.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}
