/* @font-face {
    font-family: 'tradeGothic';
    src: url('./Fonts/HCo_Forza/OpenType/Forza-Thin.otf')
    	format('opentype');

}  */

/* *{
list-style-type: none !important;
font-family: 'tradeGothic', sans-serif;
};  */

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* After an upgrade to MUI 4.X, we saw focus box shadows.  This is the only way we found to turn them off. */
/* *:focus{
  box-shadow: none !important;
} */

.MainContent {
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  position: relative;
  min-height: 85vh;
}

@media only screen and (max-width: 1279px) {
  .MainContent {
    margin-top: 64px;
  }
}

@media only screen and (max-width: 600px) {
  .MainContent {
    margin-top: 56px;
  }
}

hr {
  margin-bottom: 25px;
  margin-top: 18px;
}
