/* a,
a:hover,
a:focus {
  color: white;
} */

.primaryLink,
.primaryLink:hover,
.primaryLink:focus {
  color: #ed8b00;
}
